<template>
  <div id="item">
    <div id="thumbnail">
      <img :src="thumbnail"/>
    </div>
    <div id="content">
      <span class="detail">{{time}}</span>
      <span class="name">{{newsName}}</span>
      <span class="detail" style="margin-top:15px">{{newsDetail}}</span>
      <p class="detailButton" @click="handleClick">查看详情</p>
    </div>
  </div>
</template>

<script>
export default {
  name:"NewsCard",
  props:[
    "time",
    "newsName",
    "newsDetail",
    "thumbnail",
    "id"
  ],
  methods:{
    handleClick(){
      this.$router.push(`/news/detail/${this.id}`)
    }
  }
}
</script>

<style scoped>
    #item{
      width: 100%;
      max-width: 386px;
      max-height: 488px;
      border: 1px solid #eee;
    }
    @media (max-width:640px){
      #item{
        margin: auto;
      }
    }
    #thumbnail{
      width: 100%;
      height: 60%;
      max-height: 276px;
      overflow: hidden;
    }
    #thumbnail img{
      width: 100%;
      height: 276px;
      display: block;
      object-fit: cover;
    }
    #item:hover{
      border: 1px solid #337ab7;
    }
    #item:hover img{
      transform: scale(1.5,1.5);
      transition: 500ms;
    }
    #item:hover .detailButton{
      background-color: #337ab7;
      color: white;
      animation: fadeIn;
      animation-duration: 0.5s;
    }
    #content{
      display: block;
      padding: 30px 30px 10px 30px;
    }
    .detail{
      display: inline-block;
      width: 100%;
      font-size: 14px;
      color: #999;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }
    .name{
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .detailButton{
      text-align: center;
      width: 100%;
      padding: 10px 0;
      font-size: 13px;
      color: #666;
      cursor: pointer;
      background-color: #F6F6F6;
    }
    @media (max-width:640px){
      .detail{
        font-size: 12px;
      }
      .name{
        font-size:14px
      }
      .detailButton{
        font-size: 12px;
      }
    }
</style>