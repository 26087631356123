<template>
    <div class="item" @click="handleClick">
        <div class="product_logo">
            <img 
                :src="logo_url" 
                width="100%"
                height="100%"
            />
        </div>
        <div class="product_description">
            <span class="product_description_Title">
                {{productName}}
            </span>
            <span class="product_description_Detail">{{description}}</span>
            <span class="goDetail">查看详情+</span>
        </div>
    </div>
</template>

<script>
export default {
    name:"ProductCard",
    props:[
        "id",
        "logo_url",
        "productName",
        "description",
        // detail_url
    ],
    methods:{
        handleClick(){
            this.$router.push(`/product/${this.id}`)
        }
    }
}
</script>

<style scoped>
    .item{
        width: 49%;
        max-height: 198px;
        box-sizing: border-box;
        padding: 30px;
        display: flex;
        border: 1px solid rgb(242, 242, 242);
        margin-bottom: 20px;
        cursor: pointer;
    }
    
    .item:hover{
        border: 1px solid rgb(216, 216, 216)
    }
    .product_logo{
        max-width: 88.5px;
        min-width: 88.5px;
        height: 88.5px;
        flex: 1;
        margin: auto;
    }

    .product_description{
        flex:5;
        margin-left: 30px;
        padding-right: 30px;
    }
    .product_description_Title{
        display: block;
        color: #333;
        font-size: 1.725rem;
        line-height: 133.3%;
    }
    
    .product_description_Detail{
        display: -webkit-box;
        font-size: 18px;
        color: rgb(119, 119, 119);
        text-align: justify;
        font-family: "Microsoft YaHei";
        margin-top: 5px;
        line-height: 24px;
        max-height: 48px;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
        overflow:hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }
    .goDetail{
        color: rgb(46, 84, 151);
        font-size: 16px;
        display: block;
        margin-top: 10px;
    }
    @media (max-width:640px) {
        .item{
            width: 100%;
            padding: 15px;
        }
        .product_logo{
            max-width: 88.5px;
            min-width: 88.5px;
            height: 88.5px;
        }
        .product_description{
            padding-right: 30px;
            margin-left: 30px;
        }
        .product_description_Title{
            font-size:1.25rem
        }
        .product_description_Detail{
            line-height: 24px;
            max-height: 44px;
        }
        .goDetail{
            font-size: 14px;
        }
    }
    
</style>