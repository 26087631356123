<template>
  <div style="min-height:200px;padding: 0px 3.3%;">
    <Title chinese="产品与解决方案" english="PRODUCTS" />
    <div id="product_list">
        <ProductCard 
            v-for="(item,index) in productList" :key="index"
            :id="item.id"
            :logo_url="item.logo"
            :productName="item.name"
            :description="item.description"
         />
    </div>
  </div>
</template>

<script>
import Title from '../common/ModuleTitle'
import ProductCard from '../common/ProductCard'

export default {
    name:"ProductList",
    components:{
        Title,
        ProductCard
    },
    data(){
        return{
            productList:[
                {
                    id:1,
                    logo:"https://pic.imgdb.cn/item/63ae65fd08b6830163e25749.png",
                    name:"第一实验室",
                    description:"晶准通信建设有第一实验室，可提供晶圆取片、贴片、金丝键合、镜检等芯片研发的微组装工艺技术，支持芯片探针测试、同轴测试等毫米波芯片或模块测试，最高支持110GHz。"
                },
                {
                    id:2,
                    logo:"https://pic.imgdb.cn/item/63ae666408b6830163e30a9f.png",
                    name:"毫米波通信",
                    description:"晶准通信为5G 毫米波通信相关的基站天线、移动终端、固定无线接入终端提供多通道毫米波波束赋形芯片和毫米波AIP终端，推出了JC1101/JC1103/JC1105等芯片，在行业中具备优异的性能和最好的性价比。"
                },
                {
                    id:3,
                    logo:"https://pic.imgdb.cn/item/63ae666408b6830163e30aa6.png",
                    name:"毫米波感知",
                    description:"晶准通信面向汽车感知、智慧道路、日常生活、安全检测、医学监控等领域提供高性能的雷达感知毫米波芯片，推出JC1501/JC1509T/JC1509R/JC1505T/JC1505R的雷达芯片组，具有行业最高的性能和最优的性价比。"
                }
            ]
        }
    }
}
</script>

<style scoped>
    #product_list{
        display: flex;
        flex-wrap: wrap;  
        width: 100%;
        max-width: 1200px;
        padding-top: 30px;
        margin: auto;
        justify-content: space-between;
    }
    @media (max-width:640px) {
        #product_list{
            padding-top:0px;
            display: block;
        }
    }
</style>