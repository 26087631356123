<template>
  <div style="min-height:200px;padding-bottom: 50px;">
    <Title chinese="新闻中心" english="NEWS CENTER" />
    <div id="newsList">
      <div id="cardList" v-for="(item,index) in newsList" :key="index">
        <NewsCard 
          :id="item.id"
          :time="item.releaseTime"
          :newsName="item.newsTitle"
          :newsDetail="item.newsContent"
          :thumbnail="item.thumbnailUrl"
          style="margin-bottom:20px"
        />
      </div>
    </div>
      
    <div class="mode" @click="handleClick">
      <div class="more">查看更多</div>
    </div>
  </div>
</template>
  
  <script>
  import Title from '../common/ModuleTitle'
  import NewsCard from '../common/NewsCard'

  export default {
    name:"NewsList",
    components:{
      Title,
      NewsCard
    },
    data(){
      return{
        newsList:[]
      }
    },
    methods:{
      handleClick(){
        this.$router.replace('/news')
      }
    },
    created(){
      this.$axios.get('/read/getNews').then(res => {
        this.newsList.push(...res)
      })
    }
  }
  </script>
  
  <style scoped>
    #newsList{
      display: flex;
      max-width: 1200px;
      margin: auto;
      justify-content: flex-start;
      padding-top: 30px;
    }
    #cardList{
      margin-left: 3.3%;
      width: 30%;
    }
    @media (max-width:640px){
      #newsList{
        padding: 0px 3.3%;
        display: block;
      }
      #cardList{
        width: 100%;
        margin-left: 0px;
      }
    }
    .more {
      width: max-content;
      margin: auto;
      margin-top: 30px;
      color: white;
      padding: 10px 50px;
      background-color: rgb(0, 83, 161);
    }
    .mode{
      width: max-content;
      margin: auto;
      cursor: pointer;
    }
    .mode:hover .more{
      background-color: rgb(0, 176, 240);
      animation: zoomIn;
      animation-duration: 0.5s;
    }
  </style>