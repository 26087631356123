<template>
  <div style="margin-top: 0px;">
    <img
      src="https://pic.imgdb.cn/item/63ae64ed08b6830163e08f70.jpg"
      style="width: 100%"
      />
      <ProductList />
      <NewsComponent />
  </div>
</template>

<script>
import NewsComponent from '../components/homepage_components/NewsComponent';
import ProductList from '../components/homepage_components/ProductList';

export default {
  name:"Homepage",
  components:{
    NewsComponent,
    ProductList
  }
}
</script>
